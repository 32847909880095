<template>
  <a class="containerLinkFilter" :href="url" target="_self">{{label}}</a>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'linkFilter',
  props: {
    propertyName: {
      type: String,
      requred: true
    },
    label: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      required: false,
      default: 'Intelligence'
    }
  },
  setup (props) {
    /**
     * @description Creates the url link based on the property name and value.
     */
    const url = computed(() => {
      const filter = encodeURIComponent(`${formatFilter()}`)
      if (props.source === 'Intelligence') {
        return `${location.origin}/resultsIntelligence?q=&t=8&k=1&s=${props.source}&f=${filter}&o=date_created%20desc`
      }
      return `${location.origin}/resultsWork?q=&t=8&k=1&s=${props.source}&f=${filter}&o=date_created%20desc`
    })

    /**
     * @description Creates the right filter-format
     */
    function formatFilter () {
      if (props.propertyName === 'categories' && props.source !== 'Intelligence') {
        return (`industry::${props.label}`)
      } else {
        return `${props.propertyName}::${props.label}`
      }
    }

    return {
      url
    }
  }
}
</script>
