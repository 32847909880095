<template>
  <section class="detailCommentContainer">
    <section class="detailCommentContainer__avatarName">
      <div class="detailCommentContainer__avatarName--image">
        <img :src="thumbnail(comment.thumbnail)" alt="People" />
      </div>
    </section>
    <section class="detailCommentContainer__comment">
      <section class="detailCommentContainer__comment--text" v-if="!isEditingComment">
        <div class="commentAuthorAndDate">
          <a class="commentAuthorAndDate__author"
            @mouseover="openPopupPersonalInfo($event, true)"
            @mouseleave="openPopupPersonalInfo($event, false)"
            :href="link"
            target="_self"
            :id="`comment-${position}`">
            {{comment.userName}}
            <popupPersonalInfoGeneric
              v-if="openPopup && userDataLoaded"
              :popupPosition="popupPosition"
              :personalInfoProp="store.state.managerInfo"
              :profileLink="link"
              :isSearchApp="true"
              @click.stop.prevent>
            </popupPersonalInfoGeneric>
          </a>
          <span class="commentAuthorAndDate__date">{{commentDateFormatted}}</span>
        </div>
        <section class="detailCommentContainer__comments">
          <section class="detailCommentContainer__comments--staticComment">
            <span class="commentContent">{{comment.content}}</span>
          </section>
        </section>
      </section>
      <editableComment v-else class="newComment" :articleId="articleId" @commentSaved="refreshComment" :text="comment.content" ref="editableCommentRef" :commentId="comment.id"  @cancelEdition="cancelEdition"/>
      <section class="showMore__actionButtons" v-if="authorIsLogged && !isEditingComment">
        <span @click="activateEdition" class="grey-icon-edit showMore__actionButtons--edit"></span>
        <span @click="openDeleteConfirmation" class="icon-grey-delete showMore__actionButtons--delete"></span>
      </section>
    </section>
    <deleteCommentModalGeneric v-if="showDeleteConfirmation" @close="closeModalConfirmation" :itemId="articleId" :commentId="comment.id" @refresh="emit('refreshList')"></deleteCommentModalGeneric>
  </section>
</template>

<script>
import { onUnmounted, computed, nextTick, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import editableComment from '@/components/editableComment/editableComment'
import moment from 'moment'

export default {
  props: ['comment', 'articleId', 'position'],
  components: {
    editableComment
  },
  name: 'detailComment',
  emits: ['refreshList', 'updateCommentContent'],
  setup (props, { emit }) {
    const store = useStore()
    const areVisibleActionButtons = ref(false)
    const isEditingComment = ref(false)
    const showDeleteConfirmation = ref(false)
    const openPopup = ref(false)
    const popupPosition = reactive({
      right: 0,
      top: 0,
      bottom: 0
    })
    const requestControl = ref(null)
    const editableCommentRef = ref(null)
    const userDataLoaded = ref(false)

    /**
     * @description Indicates if author of the comment is the current user logged.
     */
    const authorIsLogged = computed(() => {
      return props.comment.userId === store.state.dataUserLogin.ecCode
    })

    /**
     * @description Indicates if author of the comment is the current user logged.
     */
    const commentDateFormatted = computed(() => {
      const date = new Date(`${props.comment.date.replace(/-/g, '/')} UTC`)
      return moment(date).format('MMMM DD, YYYY, h:mm A')
    })

    /**
     * @description Build query to redirect to profile user clicked
     */
    const link = computed(() => {
      if (!props.comment.displayUserInfo) {
        return null
      }
      return process.env.VUE_APP_NODE_ENV === 'prd' ? `https://people.grey.com/profile/${props.comment.userId}` : `https://${process.env.VUE_APP_NODE_ENV}-people.grey.com/profile/${props.comment.userId}`
    })

    onUnmounted(() => {
      store.commit({
        type: 'GET_MANAGER_INFO',
        managerInfo: {},
        numberOfComment: null
      })
    })

    /**
     * @description Shows the actions buttons.
     */
    function showActionButtons () {
      areVisibleActionButtons.value = true
    }

    /**
     * @description Hides the actions buttons.
     */
    function hideActionButtons () {
      areVisibleActionButtons.value = false
    }

    /**
     * @description Enable edition mode of the comment.
     */
    function activateEdition () {
      areVisibleActionButtons.value = false
      isEditingComment.value = true
      nextTick(() => {
        editableCommentRef.value.focus()
      })
    }

    /**
     * @description Saves the edited comment.
     * @param commentEdited new comment text
     * @param commentDate new comment date
     */
    function refreshComment (commentEdited, commentDate) {
      emit('updateCommentContent', commentEdited, commentDate, props.position)
    }

    /**
     * @description Disables edition mode.
     */
    function cancelEdition () {
      isEditingComment.value = false
    }

    /**
     * @description Opens confirmation modal to delete a comment.
     */
    function openDeleteConfirmation () {
      showDeleteConfirmation.value = true
      areVisibleActionButtons.value = false
    }

    /**
     * @description Closes confirmation modal to delete a comment.
     */
    function closeModalConfirmation () {
      showDeleteConfirmation.value = false
    }

    /**
     * @description Get profile picture
     */
    function thumbnail (thumbnailUrl) {
      return thumbnailUrl || require('../../assets/avatar.jpg')
    }

    /**
     * @description Open and positioned popup depending coordenates to cursor and space free
     * @param event of DOM
     * @param {Bool} flag to show or hide modal
     */
    function openPopupPersonalInfo (event, flag) {
      if (!props.comment.displayUserInfo) {
        return
      }
      const link = document.getElementById(`comment-${props.position}`)
      const rect = link.getBoundingClientRect()
      const bottomSpace = window.innerHeight - rect.bottom
      const rightSpace = window.innerWidth - rect.right
      const topSpace = rect.top

      if (flag && store.state.managerInfo.id !== props.comment.userId) {
        userDataLoaded.value = false
        store.commit({
          type: 'GET_MANAGER_INFO',
          managerInfo: {},
          namePositionNumber: null
        })
      }

      if (flag && store.state.managerInfo.numberOfComment !== props.position) {
        store.dispatch({
          type: 'getManagerData',
          ecCode: props.comment.userId,
          numberOfComment: props.position
        }).then(() => {
          userDataLoaded.value = true
        })
      }

      if (!navigator.userAgent.match(/Android|iPhone|iPad/i) && store.state.managerInfo) {
        clearTimeout(requestControl.value)
        requestControl.value = setTimeout(() => {
          popupPosition.left = `${event.clientX - rect.left + 10}px`
          popupPosition.right = 'none'
          if (bottomSpace > 210 && rightSpace > 430) {
            openPopup.value = flag
            popupPosition.top = '100%'
            popupPosition.bottom = 'none'
          } else if (rightSpace > 430 && topSpace > 210) {
            openPopup.value = flag
            popupPosition.bottom = '100%'
            popupPosition.top = 'none'
          } else {
            openPopup.value = flag
            popupPosition.left = '-50px'
            popupPosition.top = '100%'
          }
        }, 500)
      }
    }

    return {
      store,
      areVisibleActionButtons,
      isEditingComment,
      showDeleteConfirmation,
      openPopup,
      popupPosition,
      requestControl,
      editableCommentRef,
      authorIsLogged,
      link,
      showActionButtons,
      hideActionButtons,
      activateEdition,
      refreshComment,
      cancelEdition,
      openDeleteConfirmation,
      closeModalConfirmation,
      thumbnail,
      openPopupPersonalInfo,
      commentDateFormatted,
      userDataLoaded,
      emit
    }
  }
}
</script>
