<template>
  <section class="commentsContainer">
    <section class="commentsContainer--title">
      <p class="commentsContainer--title-label">Comments ({{comments.length}})</p>
    </section>
    <section class="commentsContainer--readComments">
      <ul class="commentsContainer--list">
        <li class="commentsContainer--list-item" v-for="(item, index) in comments" :key="`comment-${item.id}`">
          <detailComment :comment="item" :articleId="articleId" @refreshList="emit('updateCommentList')" @updateCommentContent="updateCommentContent" :position="index"/>
        </li>
      </ul>
    </section>
    <section class="commentsContainer--sendComment">
      <div class="commentsContainer--sendComment-avatarName">
        <div class="image">
          <img :src="profilePicture" alt="People">
        </div>
      </div>
      <editableComment class="commentsContainer--sendComment-newComment" :articleId="$route.params.idCard" @commentSaved="emit('updateCommentList')"/>
    </section>
  </section>
</template>

<script>
import { computed } from 'vue'
import detailComment from '@/components/detailComment/detailComment'
import editableComment from '@/components/editableComment/editableComment'
export default {
  name: 'commentsBox',
  props: {
    articleId: {
      type: String,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    }
  },
  components: {
    detailComment,
    editableComment
  },
  emits: ['updateCommentList', 'updateCommentContent'],
  setup (props, { emit }) {
    /**
     * @description Property return the profile picture from logged user
     */
    const profilePicture = computed(() => {
      return localStorage.getItem('loginSession.profile') ? localStorage.getItem('loginSession.profile') : require('../../assets/avatar.jpg')
    })

    /**
     * @description update comment content with edited value
     * @param commentEdited new comment text
     * @param commentDate new comment date
     */
    function updateCommentContent (commentEdited, commentDate, index) {
      emit('updateCommentContent', commentEdited, commentDate, index)
    }

    return {
      emit,
      profilePicture,
      updateCommentContent
    }
  }
}
</script>
