<template>
  <section class="containerEditableComment">
    <div class="containerEditableComment__containerInput">
      <span class="containerEditableComment__containerInput--input"
            ref="inputRef"
            contenteditable @input="updateText">{{text}}</span>
    </div>
    <button class="containerEditableComment__submitButton bookFont"
            @click="saveComment"
            v-if="!isEditMode"
            :class="{disabled: !isSaveButtonEnabled}">SUBMIT</button>
    <section v-else class="containerEditableComment__containerButtons">
      <button class="containerEditableComment__containerButtons--cancelButton bookFont"
              @click="cancelEdition">CANCEL</button>
      <button class="containerEditableComment__containerButtons--saveButton bookFont"
              @click="saveComment"
              :class="{disabled: !isSaveButtonEnabled}">SAVE</button>
    </section>
  </section>
</template>

<script>
import { computed, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'editableComment',
  props: {
    text: {
      default: null,
      type: String
    },
    articleId: {
      required: true
    },
    commentId: {
      default: null
    }
  },
  emits: ['cancelEdition', 'commentSaved'],
  setup (props, { emit }) {
    const store = useStore()
    const isAPISavingData = ref(false)
    const value = ref(props.text)
    const inputRef = ref(null)

    /**
     * @description Returns a flag to know if we are editing or creating a comment.
     */
    const isEditMode = computed(() => {
      return Boolean(props.commentId)
    })

    /**
     * @description Flag to know if save button should be enable or not.
     */
    const isSaveButtonEnabled = computed(() => {
      return value.value && value.value.trim() && !isAPISavingData.value
    })

    /**
     * @description Updates the variable that stores the text value.
     */
    function updateText () {
      value.value = inputRef.value.innerText
    }

    /**
     * @description Sets the focus on the input.
     */
    function focus () {
      if (document.createRange) {
        const range = document.createRange()
        range.selectNodeContents(inputRef.value)
        range.collapse(false)

        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }

    /**
     * @description Emits an event to notify the user has cancelled edition.
     */
    function cancelEdition () {
      emit('cancelEdition')
    }

    /**
     * @description Saves the comment.
     */
    function saveComment () {
      isAPISavingData.value = true
      store.dispatch({
        type: 'saveComment',
        itemId: props.articleId,
        commentId: props.commentId,
        content: store.getters.removeWhitespaces(value.value)
      }).then(response => {
        isAPISavingData.value = false
        emit('commentSaved', store.getters.removeWhitespaces(value.value), response.data.publishDate)
        if (props.commentId) {
          nextTick(() => cancelEdition())
        }
        value.value = null
        inputRef.value.innerText = null
      })
    }

    return {
      store,
      isAPISavingData,
      value,
      inputRef,
      isEditMode,
      isSaveButtonEnabled,
      updateText,
      cancelEdition,
      saveComment,
      focus
    }
  }
}
</script>
