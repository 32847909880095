<template>
  <section class="containerSkeletonDetailPage">
    <section class="containerSkeletonDetailPage__skeleton">
      <section class="containerSkeletonDetailPage__skeleton--contentData">
        <div class="title" />
        <div class="requestToAccess" v-if="intelligenceSource" />
        <div class="mediaContainer" />
        <section class="detailFields" v-if="intelligenceSource || store.state.phoneResolution">
          <div v-for="index in 9" :key="index" />
        </section>
        <section v-else class="detailFieldsWork">
          <div v-for="index in 2" :key="index" class="detailFieldsWork__section">
            <div v-for="index in 3" :key="index" class="detailFieldsWork__section--item">
              <div></div>
              <div></div>
            </div>
          </div>
        </section>
      </section>
    </section>
    <section class="containerSkeletonDetailPage__skeletonSimilar">
      <div class="containerSkeletonDetailPage__skeletonSimilar--background">
        <div class="detail">
          <div v-for="index in 3" :key="index" class="detail__item">
            <div class="detail__item--image" />
            <div class="detail__item--description">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'skeletonDetailPage',
  props: {
    source: {
      type: String,
      default: 'intelligence'
    }
  },
  setup (props) {
    const store = useStore()

    /**
     * @description Validate if is intelligence source
     */
    const intelligenceSource = computed(() => {
      return props.source === 'intelligence'
    })

    return {
      store,
      intelligenceSource
    }
  }
}
</script>
