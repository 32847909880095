/**
 * @description Set up azure media player with additional properties
 * @param assetUril Url of the asset to be displayed
 * @param mimeType Mime type associated with the asset
 * @param captionsArray Array with closed captions
 * @param posterImage Image to show until user play the video
 */
/* eslint-disable */
export function setUpAzureMediaPlayer(assetUrl, mimeType, captionsArray, posterImage) {
  const audioFile = mimeType && mimeType.toLowerCase().includes('audio')
  var options = {
    "logo": { "enabled": false },
    techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"],
    "nativeControlsForTouch": false,
    autoplay: !audioFile,
    controls: true,
    muted: !audioFile,
    width: "100%",
    height: "100%",
    poster: posterImage
  }
  var myPlayer = amp(document.getElementById('azuremediaplayer'), options);
  myPlayer.src([{
    src: assetUrl,
    type: mimeType.replace(/mpeg/g, 'mp3')
  }],
  captionsArray);
  return myPlayer
}
/* eslint-enable */
